<template>
  <div class="messagesList">
    <ul class="listBox">
      <li
        v-for="(item, index) in listMessage"
        :key="index"
        :class="['item', item.IsRead ? '' : 'unreadItem']"
      >
        <!-- logo -->
        <van-row
          type="flex"
          align="center"
          class="infoBox"
          @click="getMessageInfo(item.Id)"
        >
          <van-col class="infoBoxLeft">
            <figure class="logo">
              <img
                class="ImgIcon"
                src="~assets/img/home/icon_home_new@2x.png"
              />
            </figure>
          </van-col>
          <van-col
            :class="[
              'infoBoxMiddle',
              item.IsTop || !item.IsRead ? '' : 'infoBoxMiddleFull',
            ]"
          >
            <p class="newsTitle">{{ item.Title }}</p>
            <div class="newsPreview">{{ item.ReleaseTime }}</div>
          </van-col>
          <van-col class="infoBoxRigth" v-if="item.IsTop || !item.IsRead">
            <p class="newsTitle" v-if="item.IsTop">{{ $t.messages.isTop }}</p>
            <div class="newsPreview" v-if="!item.IsRead">
              {{ $t.messages.unread }}
            </div>
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon } from "vant";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
Vue.use(Image);
Vue.use(Icon);

export default {
  name: "MessagesList",
  props: ["listMessage"],
  components: {},
  data() {
    return {};
  },
  methods: {
    getMessageInfo(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: `messageInfo/${id}`,
        name: "MessageInfo",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 32px;
  padding: 12px 0;
  text-align: center;
}
.messagesList {
  width: 100%;
  margin-top: 3%;
  .listBox {
    margin: 0% 3%;
    .item {
      padding: 0.3rem;
      border-radius: 38px;
      box-shadow: 0 0 0px 1px #ece7e7;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 10px;
    }
    .unreadItem {
      box-shadow: 0 0 0px 1px red;
    }
  }
  .infoBox {
    .infoBoxLeft {
      width: 20%;
      text-align: center;
      line-height: 3.5rem;
      .logo {
        margin: 0;
        .ImgIcon {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 100%;
          vertical-align: middle;
        }
      }
    }
    .infoBoxMiddleFull {
      width: 80% !important;
    }
    .infoBoxMiddle {
      width: 60%;
      padding-left: 0.4rem;
      line-height: 1.3rem;
      .infoBoxRigthTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        padding: 0;
        vertical-align: middle;
      }
      .newsTitle {
        margin: 0;
        font-size: 12px;
        font-weight: bold;
        color: #000;
        @include spanNowrap;
      }
      .newsPreview {
        font-size: 12px;
        color: #999;
      }
    }
    .infoBoxRigth {
      width: 20%;
      padding-left: 0.4rem;
      line-height: 1.3rem;
      text-align: center;
      .infoBoxRigthTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        padding: 0;
        vertical-align: middle;
      }
      .newsTitle {
        margin: 0;
        font-size: 12px;
        font-weight: bold;
        color: #01aaa3;
        @include spanNowrap;
      }
      .newsPreview {
        font-size: 12px;
        font-weight: bold;
        color: red;
      }
    }
  }
}
</style>
